import React, { useState } from "react"
import TimelineNav from "../../../components/timelinenav/timelineNav"
import CustomButton from "../../../components/Button/button"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import plus from "src/assets/images/plus.svg";
import FriendsListModal from "./friendsList/FriendsListModal";
import WalletBalance from "./WalletBalance";

import "../flics.css"


import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { sendTokensToInitiateFlics } from "src/web3/initiateFlics";

import { getFlicsAmount } from "src/web3/GetFlicsAmount";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

function ShareFlics({
  setSliderPage,
  amount,
  flicsAmount,
  friendsList,
  flicsRecipient,
  setFlicsRecipient,

}) {
  const [terms, setTerms] = useState(false);
  const [showFriendsListModal, setShowFriendsListModal] = useState(false);  

  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const spinner = useSpinner();

  return (
    <div className='page-page-wrapper flics-page-container'>
      {/* <div className="pnkbg">
        <TimelineNav />
      </div> */}

      <div className="inner-pages-container">
        <WalletBalance /> 
        
        {/*<div className="half-pnk">*/}
          {/*<div className="inner-pages-container-wrap">*/}
            {/*<h1 className="page-title">Share FLICS ..</h1>*/}
            

            {/*<h1 className="page-title">Share FLICS ..</h1>*/}
            {/*<div className="flics-wallet-balance">Wallet Balance <span><img src={dropdTokanIcon} />10,000</span></div>*/}
          {/*</div>*/}
        {/*</div>*/}

        <div className="inner-pages-container-wrap">
          <div className="flics-popup-conainer">

            {/* <div className="add-drpd-btn-wrap">
              <span>Active</span>
              <span className="add-drpd-btn">
                <span className="plus-icon"><img src={addIconWhite} /></span>
                Add DRPD
              </span>
            </div> */}

            <div className="balance mt-3">
              <span><img src={dropdTokanIcon} /></span>
              <span>{flicsAmount}</span>
            </div>

            <div className="lock-in">Lock in 00/90 days</div>
          </div>

          <div className="input-field">
            <span className="lbl">Share ROCCA with</span>

            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Select Friend"
                style={{ 'borderRight': 'none' }}
                value={flicsRecipient?.nick_name}
                disabled={!!flicsRecipient?.nick_name}
              />
              <InputGroup.Text
                style={{ 'backgroundColor': 'white' }}
                onClick={() => setShowFriendsListModal(true)}
              >
                <img id="cursor" src={plus} />
              </InputGroup.Text>
            </InputGroup>

            <div className="my-3">
              <p className="big-text">
                ROCCA will be activated once both partners accept and sign the contract. The duration of the locking period will be 6 months.
              </p>
              <p className="big-text">
                Click here to know more about ROCCA.
              </p>
              <div className="big-text">
                <Form.Check
                  type={'checkbox'}
                  label={`I agree with the Terms and conditions and Privacy policy`}
                  onClick={(event) => {
                    console.log(event);
                    setTerms(event.target.checked);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="button-wrap">
            <CustomButton
              disabled={!terms && !!flicsRecipient?.nick_name}
              onClick={async () => {
                spinner.setLoadingState(true);
                
                if (!authProvider) {
                  const smartWallet = await initWallet();
                  authProvider = smartWallet;
                }

                sendTokensToInitiateFlics(authProvider, flicsRecipient?.wallet_address, flicsAmount); /* Make it daynamically*/
                // sendFlicsDetails();
                spinner.setLoadingState(false);
                setSliderPage("flicsDetails");
              }}>
              Share..
            </CustomButton>
          </div>
        </div>
      </div>

      {
        showFriendsListModal &&
        <FriendsListModal
          showFriendsListModal={showFriendsListModal}
          setShowFriendsListModal={setShowFriendsListModal}
          selectedCard={flicsRecipient}
          setSelectedCard={setFlicsRecipient}
          friendsList={friendsList}
        />
      }

    </div >
  );
}

export default ShareFlics;
